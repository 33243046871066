import './style_files/common_elements.css';
import { Link } from 'react-router-dom';


export function LandingBanner({ bannerImage, bannerText, bannerTagline,currentPageName ,currentPageUrl}) {
    return (
        <div className="unique-landing-banner-wrapper">
            <img className="unique-landing-banner-image" src={bannerImage} alt="Description" />
            <div className="unique-landing-banner-overlay">
                <h1 className="unique-landing-banner-heading text-center">{bannerText}</h1>
                <p className="unique-landing-banner-tagline">{bannerTagline}</p>
                <p class="unique-links-para">
                    <i className="fa fa-home fa-icon fa-home-unique" aria-hidden="true"></i>
                    <Link class="unique-link-banner" to="/">Home</Link>&nbsp; &gt; &nbsp;
                    <Link class="unique-link-banner" to={currentPageUrl}>{currentPageName}</Link>
                 </p>
            </div>
        </div>
    );
}

export function OverLayDiv({OverlayImage,Heading1,Heading2,Paragraph1,Paragraph2,Paragraph3}) {
    return (
        <div className="overlay-div-container">
            <img className="overlay-div-image" src={OverlayImage} alt="Description" />
            <div className="overlay-div-overlay">
                <p className="overlay-div-heading overlay-div-heading-1 text-center">{Heading1}</p>
                <p className="overlay-div-heading overlay-div-heading-2 text-center">{Heading2}</p>
                <div className="container">
                    <div className="row">
                        <div className="text-center">
                            <p className="overlay-div-para">{Paragraph1}</p>
                            <p className="overlay-div-para">{Paragraph2}</p>
                            <p className="overlay-div-para">{Paragraph3}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
    
export function WhiteBackgroundDivWithText({Heading1,Heading2,Paragraph1,Paragraph2}){
    return (
        <div class="white-bg-div-with-text-container">
            <div class="container">
                <div class="row text-center">
                    <h1 class="white-bg-heading1">{Heading1}</h1>
                    <h3 class="white-bg-heading2">{Heading2}</h3>
                    <div class="white-bg-para-div">
                        <p class="white-bg-para">{Paragraph1}</p>
                        <p class="white-bg-para">{Paragraph2}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}