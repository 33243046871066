import {Footer ,Navbar } from './components/layout';
import { Home } from './components/home';
import { AboutUs } from './components/about_us';
import { OurServices } from './components/our_services';
import { Agents } from './components/agents';
import { ContactUs } from './components/contact_us';
import { ScheduleCall } from './components/schedule_call';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PageNotFound } from './components/page_not_found';
import { PrivacyPolicy } from './components/privacypolicy';
import { Termsofservice } from './components/terms_of_service';
import ScrollToTop from './components/ScrollToTop'; 
import ScrollToTopButton from './components/ScrollToTopButton';

import './App.css';

function App() {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop />
      <Navbar/>
      <Routes>
        <Route  path="/" element={<Home/>}/>
        <Route path='/about_us' element={<AboutUs/>}></Route>
        <Route path='/our_services' element={<OurServices/>}/>
        <Route path='/agents' element={<Agents/>}/>
        <Route path='/contact_us' element={<ContactUs/>}/>
        <Route path='/schedule_a_call' element={<ScheduleCall/>}/>
        <Route path='/privacy_policy'element={<PrivacyPolicy/>}/>
        <Route path='/terms_of_service' element={<Termsofservice/>}/>
        <Route path="*" element={<PageNotFound />} /> {/* Catch-all route */}
      </Routes>
      <></>
      <Footer/>
      <ScrollToTopButton /> {/* Add the button here */}
    </BrowserRouter>

    </>
  );
}

export default App;
