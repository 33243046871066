import React from "react";
import { LandingBanner } from "./common_elements";
import {OverLayDiv} from "./common_elements"
import BannerImage from "../assets/images/about_us_banner_div.jpg"
import SecondDivFirstImage from "../assets/images/about-us-first-image.jpg"
import SecondDivSecondImage from "../assets/images/about_us_second_image.jpg"
import GoalsCardsImage from "../assets/images/about_us_goals_div.jpg"
import './style_files/about_us.css'
import { Link } from "react-router-dom";

export function AboutUs(){
    return (
        <div>
            <LandingBanner
            bannerImage={BannerImage}
            bannerText="IBRIYAL LIVING"
            bannerTagline="Percision In Every Detail"
            currentPageName="About Us"
            currentPageUrl="/about_us"
            />
            <AboutUsSecondDiv/>
            <AboutUsThirdDiv/>
            <OurValues/>
            <OverLayDiv
            OverlayImage={BannerImage}
            Heading1="Our Commitment to Excellence"
            Heading2="Elevating Standards in Real Estate"
            Paragraph1="At IBRIYAL LIVING PROPERTIES, our commitment to excellence is unwavering. We believe that true success in the real estate market is achieved through relentless pursuit of quality and continuous improvement. Our dedication to high standards drives every aspect of our operations, from client interactions to the properties we represent."
            Paragraph2="By fostering a culture of excellence and encouraging ongoing professional development, we ensure that our clients benefit from the most current and effective real estate solutions. Our goal is to set new benchmarks in the industry, continually improving our processes and outcomes to provide superior results and achieve the highest levels of client satisfaction."
            Paragraph3="We pride ourselves on offering unparalleled service by integrating the latest industry best practices and leveraging our extensive market knowledge. Our team of experts is dedicated to refining our strategies and enhancing our services to ensure that each client receives a tailored experience that exceeds expectations."
            />
        </div>
    );
}

function AboutUsSecondDiv() {
    return (
        <div className="about-us-second-div">
        <div className="container py-4">
            <div className="row">
                <div className="col-md-6 d-flex flex-column gap-3">
                    <img src={SecondDivFirstImage} alt="" className="img-fluid" />
                    <img src={SecondDivSecondImage} alt="" className="img-fluid" />
                </div>
                <div className="col-md-6 about-us-second-div-text-part">
                    <h2 className="about-us-second-div-para-heading">Trusted Experts in Dubai’s Luxury Real Estate</h2>
                    <div className="about-us-second-div-para">
                    <p>Founded by a team of passionate real estate investors, IBRIYAL is a fusion of our founders' deep personal connections—Ibrahim and Daniyal—reflecting the familial essence that drives our commitment to excellence. With over 12 years in the industry, IBRIYAL LIVING PROPERTIES LLC has emerged as a formidable force in the Dubai real estate scene.</p>
                    <p>What sets us apart is our relentless pursuit of client satisfaction, backed by an in-depth knowledge of local markets and a global perspective on real estate trends. As your dedicated real estate advisor, we don’t just help you buy or sell properties; we offer tailored solutions that align with your financial aspirations and lifestyle needs.</p>
                    <p>Whether you're seeking a luxury residence, a profitable investment, or a commercial space to grow your business, IBRIYAL LIVING PROPERTIES is your trusted partner in making informed decisions that resonate with your long-term vision.We transcend conventional brokerages by prioritizing your objectives and goals, focusing on forming sustainable relationships.</p>
                    <Link to={'/contact_us'}>
                        <button className="contact-us-button">Contact Us</button>
                    </Link>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}

function AboutUsThirdDiv() {
    return (
        <div 
            className="about-us-third-div-container d-flex justify-content-center align-items-center py-4" 
            style={{ backgroundImage: `url(${GoalsCardsImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
        >
            <div className="container text-center">
                <div className="about-us-row row">
                    <div className="about-us-card-col col-md-6 d-flex justify-content-center mb-4">
                        <div className="about-us-card about-us-card-landscape">
                            <div className="about-us-card-body">
                                <h5 className="about-us-card-title">Our Vision</h5>
                                <p className="about-us-card-text">At IBRIYAL LIVING PROPERTIES, our primary goal is to deliver tailored real estate solutions that align precisely with each client's unique investment aspirations. We are committed to offering unparalleled market insights, ensuring our clients benefit from opportunities that go beyond the ordinary. By focusing on building sustainable, long-term relationships, we prioritize our clients' objectives and consistently exceed their expectations.</p>
                            </div>
                        </div>
                    </div>
                    <div className="about-us-card-col col-md-6 d-flex justify-content-center mb-4">
                        <div className="about-us-card about-us-card-landscape">
                            <div className="about-us-card-body">
                                <h5 className="about-us-card-title">Our Mission</h5>
                                <p className="about-us-card-text">Our mission at IBRIYAL LIVING PROPERTIES is to be your strategic partner and trusted advisor in Dubai's real estate landscape. Driven by a commitment to "Precision in Every Detail," we tailor every aspect of your real estate journey to your individual investment goals. Founded on the deep personal connections of our founders, Ibrahim and Daniyal, we bring a familial essence to our approach, ensuring a dedication to excellence.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function OurValues() {
    return (
        <div className="container service-offered-container">
            <div className="row text-center mb-4">
                <h1 className="our-services-heading">Our Values</h1>
            </div>
            <div className="row">
                {[
                    {
                        title: 'Personalized Service',
                        icon: 'fa-user',
                        color: 'black',
                        textColor: 'white',
                        description: 'IBRIYAL LIVING PROPERTIES offers highly personalized real estate services tailored to each client’s goals. We ensure every detail of your property journey is managed with care and precision to meet your specific needs.'
                    },
                    {
                        title: 'Market Expertise',
                        icon: 'fa-bar-chart',
                        color: 'white',
                        textColor: 'black',
                        description: 'With over 12 years in Dubai’s real estate market, IBRIYAL LIVING PROPERTIES provides deep industry knowledge. We offer valuable insights and effective guidance to navigate market opportunities successfully.'
                    },
                    {
                        title: 'Commitment to Excellence',
                        icon: 'fa-trophy',
                        color: 'black',
                        textColor: 'white',
                        description: 'Our commitment to “Precision in Every Detail” ensures high standards of service. IBRIYAL LIVING PROPERTIES delivers superior results by focusing on every aspect of your real estate needs with excellence.'
                    },
                    {
                        title: 'Sustainable Relationships',
                        icon: 'fa-handshake-o',
                        color: 'white',
                        textColor: 'black',
                        description: 'We build and nurture long-term client relationships by prioritizing your objectives. IBRIYAL LIVING PROPERTIES is dedicated to exceptional service and fostering lasting trust through our commitment to you.'
                    },
                    {
                        title: 'Transparency and Ethics',
                        icon: 'fa-gavel',
                        color: 'black',
                        textColor: 'white',
                        description: 'IBRIYAL LIVING PROPERTIES upholds transparency and high ethical standards. We foster trust through honest practices, ensuring that you receive reliable and ethical service throughout your real estate experience.'
                    },
                    {
                        title: 'Innovative Adaptability',
                        icon: 'fa-rocket',
                        color: 'white',
                        textColor: 'black',
                        description: 'We stay ahead by continuously adapting to market changes. IBRIYAL LIVING PROPERTIES uses innovative strategies to meet evolving client needs and maintain a competitive edge in the real estate industry.'
                    }
                ].map((value, index) => (
                    <div key={index} className="col-md-4 d-flex align-items-stretch mb-4">
                        <div className="card" style={{ backgroundColor: value.color, color: value.textColor }}>
                            <div className="card-body">
                                <i className={`fa ${value.icon} fa-icon`} style={{ color: value.textColor }}></i>
                                <h4 className="card-title">{value.title}</h4>
                                <p className="card-text">
                                    {value.description}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}


function CustomerReview(){
    return (
        <div class="testimonials-container">
            <div class="container">
                <div class="row">
                    
                </div>
            </div>
        </div>
    );
}
