/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { Link } from 'react-router-dom';
import video from '../assets/videos/dubai-compressed.mp4';
import banner_image_for_small_screen from '../assets/images/banner_home_page_image_for_sm_screens.jpg'
import prop1_picture from '../assets/images/services_div.jpg'
import why_invest_in_dubai_image from '../assets/images/why_dubai_div.jpg'
import './style_files/home.css';

export function Home() {
    return (
        <div>
            <LandingBanner />
            <Marquee />
            <ServicesOverviewDiv/>
            <MarqueeServiceOffered/>
            <ServiceOffered/>
            <Marquee/>
            <WhyDubaiDiv/>
            <MarqueeServiceOffered/>
            <WhyChooseIbriyal/>
        </div>
    );
}

function LandingBanner() {
    return (
        <div>
        <div className="video-container visible-on-large-screens">
            <div className="text-overlay-black-bg"></div>
            <video className="fullscreen-video" autoPlay muted loop>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="text-overlay">
                <p className="banner-heading heading-1 text-center">Luxury Living</p>
                <p className="banner-heading heading-2 text-center">Dubai's Finest Real Estate</p>
                <p className="banner-heading heading-3 text-center">Exceptional Homes. Exceptional Service.</p>
                <Link to={'/about_us'}>
                    <button className="learn-more-button">Learn More</button>
                </Link>
            </div>
        </div>
        <div className="video-container visible-on-small-screens">
            <div className="text-overlay-black-bg"></div>
            <img style={{opacity:'0.7'}} className="fullscreen-video" src={banner_image_for_small_screen}/>
            <div className="text-overlay">
                <p className="banner-heading heading-1 text-center">Luxury Living</p>
                <p className="banner-heading heading-2 text-center">Dubai's Finest Real Estate</p>
                <p className="banner-heading heading-3 text-center">Exceptional Homes. Exceptional Service.</p>
                <Link to={'/about_us'}>
                    <button className="learn-more-button" style={{fontWeight:'bolder'}}>Learn More</button>
                </Link>
            </div>
    </div>
    </div>
    );   
}

function Marquee() {
    return (
        <div className="marquee-container">
            <div className="marquee">
                <p>EMAAR</p>
                <p>DUBAI PROPERTIES</p>
                <p>DAMAC</p>
                <p>NAKHEEL</p>
                <p>MEERAS</p>
                <p>EMAAR</p>
                <p>DUBAI PROPERTIES</p>
                <p>DAMAC</p>
                <p>MEERAS</p>
                <p>AZIZI DEVELOPMENTS</p>
                <p>DANUBE PROPERTIES</p>
                <p>SOBHA REALTY</p>
                <p>THE FIRST GROUP</p>
                <p>DEYAAR DEVELOPMENT</p>
                <p>SHAPOORJI PALLONJI</p>
                <p>UNION PROPERTIES</p>
                <p>ELLINGTON PROPERTIES</p>
                <p>OMNIYAT</p>
                <p>SOUTH LIVING</p>
                <p>PRESTIGE ONE DEVELOPERS</p>
                <p>BIN GHATTI</p>
            </div>
        </div>

    );
}

function ServicesOverviewDiv(){
    return (
        <div class="services-overview-container">
           <img  class="services-overview-image" src={prop1_picture} alt="Description" />
            <div class='services-overlay'>
                <p class="services-heading text-center">IBRIYAL LIVING: Your Trusted Partner</p>
                <p class="services-heading text-center">in Finding the Perfect Property</p>
                <div class="container">
                    <div class="row">
                        <div class="text-center">
                            <p class="services-para">At IBRIYAL LIVING, we know buying a property is a major decision. Our Dubai Real Estate Experts offer comprehensive support throughout the process, leveraging our market knowledge to provide tailored insights and recommendations. We ensure your choices align with your financial goals and lifestyle.</p>
                            <p>We go beyond just finding properties; we strive to make your experience smooth and enjoyable. Our team is here to answer your questions and guide you every step of the way, ensuring a rewarding and stress-free real estate journey.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function MarqueeServiceOffered() {
    return (
        <div className="marquee-container">
            <div className="marquee">
           
            <p>SALES</p>
            <p>LEASING</p>
            <p>MANAGEMENT</p>
            <p>CONSULTING</p>
            <p>PROPERTY VALUATION</p>
            <p>MARKET ANALYSIS</p>
            <p>INVESTMENT ADVISORY</p>
            <p>SELLING</p>
            <p>MORTGAGE</p>
            <p>RELOCATION</p>
            <p>REAL ESTATE MARKETING</p>
            <p>PROPERTY MANAGEMENT</p>
            <p>MAINTENANCE</p>
            <p>REAL ESTATE CONSULTATION</p>
            <p>TENANT PLACEMENT</p>
            <p>PORTFOLIO</p>
            <p>VACATION HOME RENTALS</p>
            <p>LUXURY SERVICES</p>
            <p>PROPERTY SEARCH</p>
            <p>COMMERCIAL LEASING</p>

            </div>
        </div>

    );
}

function ServiceOffered() {
    return (
        <div className="container service-offered-container">
            <div className="row">
                <h1 className="our-services-heading text-center">Our Services</h1>
                
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <i class="fa fa-lightbulb-o fa-icon"></i>
                            <h4 className="card-title">Strategic Investment Advisory</h4>
                            <p className="card-text">Tailored Buying, Selling, and Long-term Leasing. Data-driven Property Search and Selection</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                        <i class="fa fa-line-chart fa-icon"></i>
                            <h4 className="card-title">Strategic ROI Advisory</h4>
                            <p className="card-text">Expert Valuations for Informed Decision-Making. Thorough Tenant Evaluation for Easy Leasing.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                        <i class="fa fa-home fa-icon" aria-hidden="true"></i>
                            <h4 className="card-title">Vacation Property Rentals</h4>
                            <p className="card-text">Strategic Lease Optimization. Transparent Revenue Distribution Maximization and Allocation.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                        <i class="fa fa-hotel fa-icon" aria-hidden="true"></i>
                            <h4 className="card-title">Holiday Homes</h4>
                            <p className="card-text">Handpicked Portfolio of Luxury Properties. Effortless Guest Experience Supported On-site</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                        <i class="fa fa-key fa-icon" aria-hidden="true"></i>

                            <h4 className="card-title">Property Management</h4>
                            <p className="card-text">In-depth Financial Analysis for Strategic Decision-Making. Proficient Tenant Oversight Ensuring Dependable Occupancy</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                        <i class="fa fa-tasks fa-icon" aria-hidden="true"></i>

                            <h4 className="card-title">Property Maintenance</h4>
                            <p className="card-text">Preventative Maintenance and Timely Repairs. Elevated Property Worth via Thoughtful Enhancements.  </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function WhyDubaiDiv(){
    return (
        <div class="services-overview-container">
           <img  class="services-overview-image" src={why_invest_in_dubai_image} alt="Description" />
            <div class='services-overlay'>
                <p class="services-heading text-center">Dubai: Where Investment Dreams Turn into Reality</p>
                <p class="services-heading text-center">Why Invest in Property in Dubai?</p>
                <div class="container">
                    <div class="row">
                        <div class="text-center">
                            <p class="services-para">Dubai is an exceptional choice for real estate investment due to its tax-free status on property ownership. This means investors can keep more of their returns, complemented by rental yields of up to 12%. These factors make Dubai an appealing market for those looking to maximize their investment potential.</p>
                            <p class="services-para">The city's strong and stable economy, paired with world-class infrastructure, creates a secure investment environment. Dubai’s continuous development and modernization ensure that real estate investments are supported by a solid economic foundation.</p>
                            <p class="services-para">Additionally, Dubai's global reputation is bolstered by major international events, such as the Dubai Expo and high-profile sports competitions, which attract global attention and drive demand for rental properties.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

function WhyChooseIbriyal(){
    return(
        <div className="container service-offered-container">
            <div className="row">
                <h1 className="our-services-heading text-center">Why Choose IBRIYAL LIVING?</h1>
                
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                            <i class="fa fa-handshake-o fa-icon"></i>
                            <h4 className="card-title">Strategic Partnership</h4>
                            <p className="card-text">At IBRIYAL LIVING, we don't just facilitate transactions; we become your strategic ally. Our focus is on understanding your investment goals and providing tailored market insights, ensuring that your investments in Dubai's dynamic market. </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                        <i class="fa fa-trophy fa-icon"></i>
                            <h4 className="card-title">Unwavering Dedication</h4>
                            <p className="card-text">We are committed to offering personalized, premium services that truly understand your needs. Our deep knowledge of Dubai's real estate landscape allows us to provide an unparalleled experience, ensuring your satisfaction and success.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body">
                        <i class="fa fa-arrows-alt fa-icon" aria-hidden="true"></i>
                            <h4 className="card-title">Expertise and Adaptability</h4>
                            <p className="card-text">Our team is well-versed in Dubai's diverse districts and emerging trends. We provide expert advice to help you make informed decisions. As the real estate market evolves, we adapt our strategies to keep your investments strong and profitable.</p>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    );
}