import React from 'react';
import './style_files/ScrollToTopButton.css'; // Import CSS for styling

const ScrollToTopButton = () => {
  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <button className="scroll-to-top"  onClick={scrollToTop}>
      <i class='fa fa-angle-double-up' style={{fontsize:'24px'}}></i>
    </button>
  );
};

export default ScrollToTopButton;
