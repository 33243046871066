import React from "react";
import './style_files/terms_of_service.css';

export function Termsofservice(){
    return(
        <div className="unique-container">
            <div className="container">
                <div className="row">
            <div className="unique-terms-heading text-center">
                <h1><b>Terms Of Service</b></h1>
            <div className="unique-terms-content1 text-start"> 
                <p><b>Welcome to Ibriyal Real Estate
                    By accessing or using our website , you agree to be bound by these Terms of Service. 
                    If you do not agree with any part of these terms, you should not use our website or services</b>.</p>

                <div className="unique-terms-content2 text-start">
                    <h1><b>Use Of Service</b></h1>
                    <ul>
                        <li>
                        <b>Eligibility</b>: You must be at least 18 years old to use our services. By using our services,
                         you represent that you meet this age requirement.
                        </li>
                        <li>
                            <b>Account Responsibility:</b> You are responsible for maintaining the confidentiality of your 
                            account information and for all activities that occur under your account. You agree to notify us immediately
                             of any unauthorized use of your account.
                        </li>
                        <li>
                        <b>Prohibited Activities</b>: You agree not to engage in any activities that are unlawful, harmful, or disruptive
                         to our services, including but not limited to spamming, hacking, or distributing malware.

                        </li>
                    </ul>
                <div className="unique-terms-content3 margin:top">
                    <h1><b>Services</b></h1>
                    <ul>
                        <li>
                        <b>Real Estate Brokerage</b>: We provide real estate brokerage services including sales, leasing, and property management. 
                        All transactions are subject to applicable laws and regulations.
                        </li>
                        <li>
                        
                       <b> Listings</b>: Property listings on our website are for informational purposes only and are subject to change without notice.
                         We do not guarantee the accuracy or completeness of the listings.
                        </li>
                        <li>
                        <b>Fees and Payments</b>: Any fees for our services will be agreed upon in writing. Payments must be made in accordance with the agreed terms.
                        </li>
                    </ul>
                <div classNmae="unique-terms-content4">
                    <h1><b>Intellectual Property</b></h1>
                    <p>
                    All content on our website, including text, graphics, logos, images, and software, is the property of Ibriyal Real Estate or its licensors and is protected
                     by intellectual property laws. You may not use, reproduce, or distribute any content without our prior written consent.
                    </p>
                <div className="unique-terms-content5">
                    <h1><b>Limitation of Liability</b></h1>
                    <p>
                    To the fullest extent permitted by law, Ibriyal Real Estate shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or
                     any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:
                    </p>
                    <ul>
                        <li>
                        Your use or inability to use our services.
                        </li>
                        <li>
                            
                           Any unauthorized access to or use of our servers and/or any personal information stored therein.
                        </li>
                        <li>
                        Any interruption or cessation of transmission to or from our services.

                        </li>
                        <li>
                        Any bugs, viruses, or the like that may be transmitted to or through our services by any third party.
                        </li>
                    </ul>
                <div className="unique-terms-content6">
                    <h1><b>Indemnification</b></h1>
                    <p>
                    You agree to indemnify and hold harmless Panache Homes Real Estate, its affiliates, officers, directors, employees,
                     and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable legal fees and costs, 
                     arising out of or in any way connected with your access to or use of our services.
                    </p>
                </div>

                <div className="unique-terms-content7">
                    <h1><b>Governing Law</b></h1>
                    <p>
                    These Terms of Service shall be governed by and construed in accordance with the laws of the United Arab Emirates. Any disputes arising under 
                    or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of Dubai.
                     </p>
                <div className="unique-terms-content8">
                    <h1><b>Changes to Terms of Service</b></h1>
                    <p>
                    We reserve the right to modify these Terms of Service at any time. We will notify you of any changes by posting the new Terms of Service on our website. 
                    Your continued use of our services after such modifications constitutes your acceptance of the new terms.
                    </p>
                <div className="unique-terms-content9">
                    <h1><b>Contact Us</b></h1>
                    <p>If you have any questions about these Terms of Service, please contact us at:</p>
                    <ul>
                        <li>
                            Ibriyal Real Estate
                        </li>
                        <li>
                        ONTARIO TOWER, Office No. 1702-014, Business Bay, Dubai, United Arab Emirates
                        </li>
                        <li>
                        +971585478660
                        </li>
                    </ul>
                </div>
                </div>
                </div>
                </div>

                </div>
            

                </div>

                </div>

                </div>


                </div>

            </div>
            

            </div>

        </div>
       
    )
}