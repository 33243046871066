import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/logo.svg';
import './style_files/layout.css';



export function Navbar(){
    return (
        <header>
        <nav class="navbar navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3">
    <div class="container-fluid">
  
        <Link class="navbar-brand" to="/" style={{ width: '10%', height: '60%' }}>
            <img class="logo-image" src={logo} alt="logo" />
        </Link>
        
   
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon" style={{ backgroundColor: '#d4be8e', borderRadius: '4px' }}></span>
        </button>
        
        
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mx-auto">
                <li class="nav-item">
                    <Link to={'/'} class="nav-link text-light">Home</Link>
                </li>
                <li class="nav-item">
                    <Link to={'/about_us'} class="nav-link text-light">About Us</Link>
                </li>
                <li class="nav-item">
                    <Link to={'/our_services'} class="nav-link text-light">Our Services</Link>
                </li>
                <li class="nav-item">
                    <Link to={'/agents'} class="nav-link text-light">Agents</Link>
                </li>
                <li class="nav-item">
                    <Link to={'/contact_us'} class="nav-link text-light">Contact Us</Link>
                </li>
                <li class="nav-item">
                    <Link to={'/schedule_a_call'} class="nav-link text-light">Schedule A Call</Link>
                </li>
            </ul>
        </div>
    </div>
</nav>

    </header>
    );
}

export function Footer(){
    return (
<footer class="footer text-center text-lg-start footer-dark">
  
  <section class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
  
  </section>
    <div class="footer-div">
    <section>
        <div class="container text-center text-md-start mt-5 ">
        
        <div class="row mt-3">
        
            <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
            
            <h6 class="text-uppercase fw-bold mb-4">
                IBRIYAL LIVING
            </h6>
            <p>
            At Ibriyal Living, we turn your vision of the perfect home into reality. Discover a range of beautifully crafted spaces designed to enhance your lifestyle.
            </p>
            <div>
        <Link to={'https://www.facebook.com/ibriyalliving.com'} class="me-4 text-reset">
            <i class="fa fa-facebook-f" ></i>
        </Link>
        <Link href="" class="me-4 text-reset">
            <i class="fa fa-twitter"></i>
        </Link>
        <Link to={'https://www.instagram.com/ibriyalliving/'} class="me-4 text-reset">
            <i class="fa fa-instagram"></i>
        </Link>
        </div>
            </div>
            
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
            
            <h6 class="text-uppercase fw-bold mb-4">
                Quick Links
            </h6>
            <p>
                <Link to={'/about_us'} class="text-reset no-underline">About Us</Link>
            </p>
            <p>
                <Link to={'/agents'} class="text-reset no-underline">Agents</Link>
            </p>
            <p>
                <Link to={'/contact_us'} class="text-reset no-underline">Contact Us</Link>
            </p>
            <p>
                <Link to={'/our_services'} class="text-reset no-underline">Our Services</Link>
            </p>
            
            </div>
            
            
            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
            
            <h6 class="text-uppercase fw-bold mb-4">
                Policy
            </h6>
            <p>
                <Link style={{textDecoration:'none',color:'white'}} to={'/privacy_policy'}>Privacy Policy</Link>
            </p>
            <p>
                <Link style={{textDecoration:'none',color:'white'}} class="text-reset no-underline" to={'/terms_of_service'}>Terms Of Service</Link>
            </p>
            </div>
        
            <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 class="text-uppercase fw-bold mb-4">Contact</h6>
                <p><i class="fa fa-home me-3"></i>ONTARIO TOWER, Office No. 1702-014, Business Bay, Dubai, United Arab Emirates</p>
                <p>
                    <i class="fa fa-envelope me-3"></i>matheen@ibriyalliving.com
                </p>
                <p><i class="fa fa-phone me-3"></i>+971 54 54 78678</p>
            </div>

        
        </div>
        
        </div>
    </section>

  <div class="text-center p-4" >
    © 2024 Copyright:
    <a class="text-reset fw-bold" href="https://mdbootstrap.com/">Ibriyalliving.com</a>
  </div>
  </div>
</footer>
    );
}