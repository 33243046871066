import React from "react";
import './style_files/PrivacyPolicy.css';

export function PrivacyPolicy() {
    return (
        <div className="privacy-policy-container">
            <div className="container">
            <div className="privacyPolicy-heading text-center">
                <h1 style={{fontFamily:'Oswald'}}> Privacy And Policy</h1>
            <div className="policy content1 text-start">
                   <p><b>Ibriyal Real Estate</b> is committed to protecting the privacy of our clients
                       and website visitors. This Privacy Policy explains how we collect, use, disclose, and safeguard your
                      information when you visit our website .Please read this policy
                      carefully to understand our practices regarding your personal information.</p>
            </div>

                <div className="privacyPolicy-content2 text-start">
                    <h1>Information we collect</h1>
                    <ul>
                        <li>
                        <b>Personal Information</b>: When you contact us, register for our services, or engage in transactions, we may collect personal
                         information such as your name, email address, phone number, postal address, and payment information.
                        </li>
                        <li>
                        <b> Non-Personal Information</b>: We may collect non-personal information about you whenever you interact with our website. This may 
                        include browser type, IP address, the pages you visit, and the time spent on those pages.
                        </li>
                    </ul>

                <div className= "privacyPolicy-content3 margin:top" >
                    <h1>How we use your Information</h1>
                    <p1>We use the information we collect for the following purposes:</p1>
                    <ul>
                        <li>
                        <b>To Provide Services:</b>: Facilitate transactions and provide you with the services you request.
                        </li>
                        <li>
                         <b>Customer Support:</b> Respond to your inquiries, provide support, and manage your account.
                        </li>
                        <b>Marketing:</b> Send you promotional materials, updates, and other information that may be of interest to you. 
                        You can opt-out of receiving these communications at any time.
                        <li>
                        <b>Improvement:</b> Analyze data to understand user behavior and improve our website and services.
                        </li>
                        <li>
                        <b>Compliance:</b> Comply with legal obligations and protect our rights and interests.
                        </li>
                    </ul>

                <div className="privacyPolicy-content4">
                    <h1>Information Sharing and Disclosure</h1>
                    <ul>
                        <li>
                            <b>Service Providers:</b>We may share your information with third-party service providers who perform services on 
                            our behalf, such as payment processing, data analysis, email delivery, and marketing assistance.
                        </li>
                        <li>
                            <b>Legal Requiremenets</b> We may disclose your information if required to do so by law or in response to valid requests
                             by public authorities (e.g., a court or a government agency).
                        </li>
                        <li>
                            <b> Business Transfers</b>In the event of a merger, acquisition, or sale of all or a portion of our assets, your personal 
                            information may be transferred as part of the transaction.
                        </li>
                    </ul>
                

                </div>

                <div className="privacyPolicy-content5">
                <h1>
                    Your rights
                </h1>
                <p1>You have the right to:</p1>
                <ul>
                    <li>
                    Access and obtain a copy of your personal information.
                    </li>
                    <li>
                    Request correction or deletion of your personal information.
                    </li>
                    <li>
                    Object to the processing of your personal information.
                    </li>
                    <li>
                    Withdraw your consent at any time.

                    </li>
                <p2>
                    To exercise these rights, please contact us
                </p2>
                </ul>
            <div className="privacyPolicy-content6">
                <h1>Security</h1>
                <p1>
                We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. 
                However, please note that no method of transmission over the internet or electronic storage is 100% secure.
                </p1>
            <div classNmae="privacyPolicy-content7">
                <h1>
                Changes to This Privacy Policy
                </h1>
                <p1>
                We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. 
                You are advised to review this Privacy Policy periodically for any changes.
                </p1>
            <div>
                <h1>
                    Contact Us
                </h1>
                <p1>
                    
                    If you have any questions about this Privacy Policy, please contact us at:
                </p1>
                <ul>
                    <li>  <b>Ibriyal Real Estate</b></li>
                    <li>
                    <b>ONTARIO TOWER, Office No. 1702-014, Business Bay, Dubai, United Arab Emirates </b> 
                    </li>

                    <li>
                        <b>+971585478660</b>
                    </li>
                    
                </ul>
            </div>

            </div>

            </div>



                
            </div>
            

                </div>
                </div>
            </div>
            </div>
        </div>
    )
}