import React from "react";
import { LandingBanner } from "./common_elements";
import { WhiteBackgroundDivWithText } from "./common_elements";
import { OverLayDiv } from "./common_elements";
import BannerImage from "../assets/images/about_us_banner_div.jpg"
import OurServicesSecondDivImage from '../assets/images/our_services_second_div.jpg'

export function OurServices(){
    return(
        <div>
            <LandingBanner
            bannerImage={BannerImage}
            bannerText="Services"
            bannerTagline=""
            currentPageName="Our Services"
            currentPageUrl="/our_services"
            />
            <WhiteBackgroundDivWithText
            Heading1="Exceptional Luxury Real Estate Brokerage in Dubai"
            Heading2="Curating Extraordinary Property Opportunities"
            Paragraph1="In Dubai's vibrant luxury market, our goal is to match you with a property that reflects your aspirations and lifestyle. With our deep industry insights and exclusive relationships with premier developers, we provide a tailored approach to secure the finest residences and investment opportunities available."
            Paragraph2=""
            />
            <OverLayDiv
            OverlayImage={OurServicesSecondDivImage}
            Heading1="Exclusive Property Transactions with Ibriyal Living"
            Heading2=""
            Paragraph1="Navigating the high-end real estate market can be complex, but with Ibriyal Living, you benefit from a team of experts committed to delivering a seamless and efficient experience. Our licensed professionals have extensive knowledge of Dubai's luxury property landscape, ensuring that your buying or selling journey is handled with the utmost expertise and care."
            Paragraph2=""
            Paragraph3="True to our ethos of simplicity, we’ve refined our process to ensure that buying or selling luxury property in Dubai is straightforward and stress-free. Begin by reaching out to Ibriyal Living via phone or email to start the process. An experienced agent will then contact you to arrange a comprehensive consultation, where we will discuss your specific needs, provide an overview of the current market, and address all financial and legal considerations. 
Once we have a clear understanding of your requirements, our team will work diligently to connect you with qualified buyers if you’re selling, or organize viewings of properties that match your criteria if you’re buying. We will guide you through every step of the negotiation process, ensuring a fair transaction, and handle all necessary paperwork to ensure a smooth and successful experience."
            />
            <WhiteBackgroundDivWithText
            Heading1="Property Management"
            Heading2="Effortless Management of Luxury Properties with Ibriyal Living"
            Paragraph1="Managing a luxury property demands meticulous attention and expertise. At Ibriyal Living, we understand the complexities involved and offer a comprehensive property management service designed to keep your investment in prime condition. From routine maintenance to administrative tasks and repairs, our dedicated team ensures that every aspect of your property is handled with professionalism. We create customized property management plans for villas, apartments, and buildings based on your specific needs. Equipped with advanced management tools, we guarantee that your property remains well-maintained and efficiently managed."
            Paragraph2="True to our commitment to simplicity, we’ve designed a straightforward approach to managing your luxury Dubai property. Start by reaching out to Ibriyal Living via phone or email to begin the process. An experienced agent will then schedule a detailed consultation to discuss your property’s needs, outline our management services, and create a tailored plan. Once engaged, we will ensure a seamless transition into managing your property, setting up all necessary services, and handling both official and day-to-day requirements. Need expert property management in Dubai? Call Ibriyal Living at +971 54 54 78678, and let our team provide you with exceptional service and support."
            />
            <OverLayDiv
            OverlayImage={OurServicesSecondDivImage}
            Heading1="Leasing"
            Heading2="Leasing a Luxury Property with Ibriyal Living"
            Paragraph1="Leasing a luxury property involves a significant commitment from both tenants and landlords, with numerous negotiations, payments, and contracts to manage. At Ibriyal Living, our licensed and experienced agents are dedicated to facilitating a smooth and hassle-free leasing process. We handle all aspects of leasing with precision, ensuring that every detail is meticulously addressed."
            Paragraph2="With an extensive network of properties and potential tenants, we quickly connect you with the ideal match. Our brokers are actively involved to ensure that all leasing proceedings are conducted legally and fairly."
            Paragraph3="At Ibriyal Living, we're dedicated to making your luxury Dubai leasing experience seamless. Start by reaching out to our team via phone or email, and one of our expert agents will arrange a meeting to discuss your needs and provide insights into the real estate market. We'll guide you through the financial and legal aspects, connect you with suitable landlords or tenants, and assist with negotiations and official paperwork to ensure a perfect match and a smooth process.Call us today at +971 54 54 78678, and our team will assist you."
            />
            <WhiteBackgroundDivWithText
            Heading1=""
            Heading2="
Looking to lease, manage, or buy luxury properties in Dubai? Contact us today at +971 54 54 78678, and our team will assist you with all your needs."
            Paragraph1=""
            Paragraph2=""
            />
        </div>
    );
}

