import React from "react";
import { LandingBanner } from "./common_elements";
import BannerImage from "../assets/images/about_us_banner_div.jpg"
import { WhiteBackgroundDivWithText } from "./common_elements";
import QRImage from "../assets/images/watsapp_qr.png"
import "./style_files/schedule_call.css"

export function ScheduleCall(){
    return(
        <div>
            <LandingBanner
            bannerImage={BannerImage}
            bannerText="Schedule A Call"
            bannerTagline=""
            currentPageName="Schedule A Call"
            currentPageUrl="/schedule_a_call"
            />
            <WhiteBackgroundDivWithText
            Heading1=""
            Heading2="Scan the QR Code Below to Connect with Our Team on WhatsApp"
            Paragraph1="We’re here to assist you! Simply scan the QR code to start a conversation with us directly on WhatsApp. We look forward to your message."
            Paragraph2=""
            />
            <ScanQR/>
        </div>
    );
}

function ScanQR()
{
    return (
        <div class="scan-qr-div">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center">
                    <img class="watsapp-qr-code-image" src={QRImage} alt="WhatsApp QR code" />
                </div>
            </div>
        </div>
    </div>
    
    );
}
