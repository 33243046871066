import React from "react";
import { useRef } from "react";
import emailjs from '@emailjs/browser';

import { LandingBanner } from "./common_elements";
import { WhiteBackgroundDivWithText } from "./common_elements";
import BannerImage from "../assets/images/about_us_banner_div.jpg"
import './style_files/contact_us.css'
import { Link } from 'react-router-dom';


export function ContactUs(){
    return(
        <div>
            <LandingBanner
            bannerImage={BannerImage}
            bannerText="Explore Dubai’s Real Estate with Us"
            bannerTagline=""
            currentPageName="Contact Us"
            currentPageUrl="/contact_us"
            />
            <WhiteBackgroundDivWithText
                Heading1=""
                Heading2="Get in Touch with Ibriyal Living"
                Paragraph1="At Ibriyal Living, we are passionate about the Dubai real estate market and committed to delivering exceptional value to our clients. Our approach is built on honesty, integrity, and a deep understanding of your needs, ensuring a personalized experience tailored to your unique requirements."
                Paragraph2="Feel free to reach out to us today with no obligation. We’re here to address all your questions and concerns and provide you with the expert guidance you need. Let's make your property journey a success together."
            />
            <ContactUsCards/>
            <GetInTouchDiv/>
            <FAQ/>
            <WhiteBackgroundDivWithText
                Heading1=""
                Heading2="Feel free to reach out to us at +971 54 54 78678. Our team is ready to assist you with any inquiries you may have."
                Paragraph1=""
                Paragraph2=""
            />
        </div>
    );
}

function ContactUsCards(){
    return (
        <div className="container service-offered-container">
            <div className="row">
                
                
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body" style={{height:'11em'}}>
                            <i class="fa fa-phone fa-icon" style={{fontSize:'2.7em'}}></i>
                            <p className="card-text" style={{fontSize:'1.1em',fontFamily:'Oswald'}}>+971 54 54 78678</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body" style={{height:'11em'}}>
                        <i class="fa fa-envelope fa-icon" style={{fontSize:'2.7em'}}></i>
                            <p className="card-text" style={{fontSize:'1.1em',fontFamily:'Oswald'}}>matheen@ibriyalliving.com</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="card">
                        <div className="card-body"  style={{height:'11em'}}>
                        <i class="fa fa-map-marker fa-icon" style={{fontSize:'2.7em'}}></i>
                            
                            <p className="card-text" style={{fontSize:'1.1em',fontFamily:'Oswald'}}>ONTARIO TOWER, Office No. 1702-014, Business Bay, Dubai, United Arab Emirates</p>
                        </div>
                    </div>
                </div>
            </div>     
        </div>
    );
}

function GetInTouchDiv(){
    const form=useRef()

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_supzrx3', 'template_4brykep', form.current, {
            publicKey: '74IuMoiEdeYU4TFwh',
          })
          .then(
            () => {
                alert("Your Form Has Successfully Been Submitted!");
            },
            (error) => {
                alert("Error Submitting Your Form!");
            },
          );
      };
    return (
        
        <div class="get-in-touch-container">
            <div class="container mt-5 get-in-touch-form-div">
                <div class="row">
                    <div class="col-md-6 get-in-touch-item">
                    <h1 style={{fontFamily:'Oswald'}}>Get In Touch !</h1>
                    <p style={{fontFamily:"sans-serif"}}>Simply fill out the form below, and a team member from Ibriyal Living will get back to you within 24 hours:</p>
                    <form ref={form} onSubmit={sendEmail}>
                <div class="form-group">
                    <label for="identity">I Am A...</label>
                    <select class="form-control" id="identity" name="identity" required>
                        <option value="" disabled selected>Select</option>
                        <option value="buyer">Buyer</option>
                        <option value="seller">Seller</option>
                        <option value="homeowner">Homeowner</option>
                        <option value="agent">Real Estate Agent</option>
                        <option value="other">Other</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="lookingTo">Looking To</label>
                    <select class="form-control" id="lookingTo" name="lookingTo" required>
                        <option value="" disabled selected>Select</option>
                        <option value="buy">Buy a Home</option>
                        <option value="sell">Sell a Home</option>
                        <option value="learn">Learn More</option>
                        <option value="partner">Partner Up</option>
                        <option value="other">Other</option>
                    </select>
                </div>
          
                <div class="form-group">
                    <label for="firstName">Full Name</label>
                    <input type="text" class="form-control" id="firstName" name="firstName" required />
                </div>
                <div class="form-group">
                    <label for="email">Email address</label>
                    <input type="email" class="form-control" id="email" name="email" required />
                </div>
                <div class="form-group">
                    <label for="message">Message</label>
                    <textarea class="form-control" id="message" name="message" rows="3"></textarea>
                </div>
                <button type="submit"  className=" submitButton">Submit</button>
            </form>
        
                </div>
            <div class="col-md-6 get-in-touch-item">
            <div className="map-container">
                    <iframe
                        id="google-maps"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.509398668473!2d55.262174599999994!3d25.1860383!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69bc6751efa5%3A0x15345538a83e31ca!2sOntario%20Tower%20Business%20Bay!5e0!3m2!1sen!2sin!4v1723703750035!5m2!1sen!2sin"
                        className="map-iframe"
                        allowFullScreen
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
            </div>
            </div>
            </div>
        </div>
    </div>

    
    );
}

function FAQ(){
    return(
<div className="faq-container">
    <div className="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <h1 class="faq-heading">Frequently Asked Questions (FAQs)</h1>
                <div class="faq-item">
                    <div class="faq-question">
                        Q. What sets Ibriyal Living apart from other real estate firms?
                    </div>
                    <div class="faq-answer">
                        Ibriyal Living is distinguished by our team of experienced professionals who are dedicated to delivering exceptional service. Our customer-focused approach ensures that every interaction is marked by professionalism, integrity, and a personal touch. We are committed to providing a seamless and satisfying experience for all our clients.
                    </div>
                </div>
                <div class="faq-item">
                    <div class="faq-question">
                        Q. What services does Ibriyal Living offer?
                    </div>
                    <div class="faq-answer">
                        At Ibriyal Living, we provide a comprehensive range of real estate services including buying, selling, leasing, and property management in the UAE. <Link to="/our_services">Click here</Link> to explore our full range of services.
                    </div>
                </div>
                <div class="faq-item">
                    <div class="faq-question">
                        Q. What types of properties do you specialize in?
                    </div>
                    <div class="faq-answer">
                        Ibriyal Living specializes in premium properties throughout the UAE. We work closely with leading developers and offer a curated selection of high-end residential and commercial properties.
                    </div>
                </div>
                <div class="faq-item">
                    <div class="faq-question">
                        Q. How do you determine your service charges?
                    </div>
                    <div class="faq-answer">
                        Our service charges are tailored to meet the specific needs of each client. Since every transaction is unique, we provide a detailed quote based on your individual requirements. Contact us via phone or email, and our team will be pleased to provide you with a customized service charge estimate.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
    );
}

